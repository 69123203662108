<template>

                    <form @submit.prevent="save();">


                        <div class="row">


                            <div class="col-md-7 col-lg-7 col-sm-7">

                                        <div class="form-group">
                                            <label for="name">Nombre del diseño</label>
                                            <input type="text" v-model="model.name" required id="name" class="form-control form-control-lg" placeholder="Escribe el nombre del modelo">
                                        </div>

                                        <div class="form-group" v-if="!$props.development_id">
                                            <label for="name">Asignar a desarrollo</label>
                                            <select class="form-control form-contol-lg" v-bind:required="!$props.development_id" v-model="model.development_id">
                                                <option selected value="">Selecciona</option>
                                            <option v-for="(option, index) in developments" :key="index" :value="option.id">{{ option.name }}</option>
                                            </select>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="name">Recámaras</label>
                                                    <input type="text" v-model="model.rooms" required id="rooms" class="form-control form-control-lg" placeholder="¿Cuantas recámaras tiene?">
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="name">Baños</label>
                                                    <input type="text" v-model="model.bathrooms" required id="bathrooms" class="form-control form-control-lg" placeholder="¿Cuantos baños tiene?">
                                                </div>
                                            </div>

                                        </div>


                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="name">M2 de construcción</label>
                                                    <input type="text" v-model="model.m2inside" required id="m2inside" class="form-control form-control-lg" placeholder="¿Cuantos m2 tiene de construcción?">
                                                </div>
                                            
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="name">M2 de terraza</label>
                                                    <input type="text" v-model="model.m2outside" required id="m2outside" class="form-control form-control-lg" placeholder="¿Cuantos m2 tiene de terraza?">
                                                </div>
                                            </div>

                                        </div>
                                        
                                        <div class="form-group">

                                            
                                            <label for="name">Precio</label>


                                            <b-input-group class="mt-3">
                                                <template #append>
                                                    <select v-model="model.currency_id" class="form-control form-control-lg">
                                                        <option v-for="(option, index) in currencies" :key="index" :value="option.id">{{ option.name }}</option>
                                                    </select>
                                                </template>
                                                <input type="text" v-number-format v-model.lazy="model.price" required id="m-price" class="form-control form-control-lg" placeholder="Escribe el precio">
                                            </b-input-group>
                                            
                                        </div>

                                        <div class="form-group">
                                            <label>Amenidades</label>
                                            <multiselect v-if="isReady" :value="selectedEquipment" @input="addEquipment" tag-placeholder="Agregar como etiqueta nueva" placeholder="Busca o agrega una etiqueta" label="name" track-by="id" :options="model.catalog_equipment" :hideSelected="true" :multiple="true" :taggable="true"></multiselect>
                                        </div>
                                
                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-5">
                                <div class="form-group">
                                    <label>Fachada</label>
                                    <img v-if="pic" class="thumb" v-bind:src="pic"/>
                                    <input type="file" v-bind:required="isNewRecord" class="form-control form-control-lg" v-on:change="selectFile" id="image" accept=".jpg,.jpeg,.png,.x-png,.gif">
                                    <!--  <span class="empty-image"><i class="mdi mdi-image-plus-outline"></i></span>-->
                                </div>



                            </div>

                            <!--
                            <div class="col-md-6 col-lg-6 col-sm-6">

                                <div class="row">

                                    <div class="col-md-6 col-lg-6 col-sm-6">

                                        <div class="form-group">
                                            <label>Fachada</label>
                                            <img v-if="pic" class="thumb" v-bind:src="pic"/>
                                            <input type="file" v-bind:required="isNewRecord" class="form-control form-control-lg" v-on:change="selectFile" id="image" accept=".jpg,.jpeg,.png,.x-png,.gif">
                                            <span class="empty-image"><i class="mdi mdi-image-plus-outline"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <label>Sala</label>
                                            <span class="empty-image"><i class="mdi mdi-image-plus-outline"></i></span>
                                          </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-6">

                                        <div class="form-group">
                                            <label>Comedor</label>
                                            <span class="empty-image"><i class="mdi mdi-image-plus-outline"></i></span>
                                          </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <label>Cocina</label>
                                            <span class="empty-image"><i class="mdi mdi-image-plus-outline"></i></span>
                                          </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-6">

                                        <div class="form-group">
                                            <label>Recámara 1</label>
                                            <span class="empty-image"><i class="mdi mdi-image-plus-outline"></i></span>
                                          </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <label>Recámara 2</label>
                                            <span class="empty-image"><i class="mdi mdi-image-plus-outline"></i></span>
                                          </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-6">

                                        <div class="form-group">
                                            <label>Baño</label>
                                            <span class="empty-image"><i class="mdi mdi-image-plus-outline"></i></span>
                                          </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <label>Terraza</label>
                                            <span class="empty-image"><i class="mdi mdi-image-plus-outline"></i></span>
                                          </div>
                                    </div>


                                </div>


                            </div>-->


                        </div>


                        <div class="form-group">

                        <submitbutton :label="isNewRecord?'Crear':'Actualizar'" :loading_flag="loading"/>

                        </div>


                    </form>



            
</template>
<script>
import submitbutton from '@/components/ui-feature/submit-button';
import api from '@/util/api.js';



export default {
    routeName:'designs',
    name: 'formDesign',
    components:{
        submitbutton
    },
    props:{

        id:{
            type:Number,
            required:false,
        },
        development_id:{
            type:Number,
            required:false,

        },
        currencies:{
            type:Array,
            required:false

        }

    },  
    data(){

        return {
            model:{
                id:0,
                name:'',
                m2inside:'',
                m2outside:'',
                bathrooms:0,
                rooms:0,
                price:'',
                currency_id:0,
                development_id:'',
                designEquipment:[],
                catalog_equipment:[]
           },
           developments:[],
           
           
            pic:'',
            loading:false,

        }

    },

    computed:{

        selectedEquipment(){
            return this.model.catalog_equipment.filter(obj => this.model.designEquipment.includes(obj.id));
        },

        isReady(){

            return this.model.id!=0?true:false;
        }

    },

    methods:{

        addEquipment(i){

            
            this.model.designEquipment=[];

            for(let j in i){
                const it=i[j];
                this.model.designEquipment.push(it.id);
            }


        },
        selectFile(event) {
         this.model.image = event.target.files[0];
        },

        save(){


            const data = this.createFormData(this.model);
            this.loading=true;

            api.request({
                url:this.me(this.isNewRecord?'/':this.model.id),
                data,
                headers:{'Content-type':'form/data'},
                method:this.isNewRecord?'POST':'PATCH',
                }).then(response=>{

                    this.$notify({message:"Elemento " +(this.isNewRecord?"creado":"actualizado"),type:"success"});
                    this.$emit('afterSave',response);
                    
                    
                }).catch(error=>{
                    
                    this.$notify({message:"Ocurrió un error",type:"error"});
                    console.log(error);

                }).finally(()=>{

                        this.loading=false;
                });
            
        }




    },


    
    
    mounted(){



        
        const dev_id=this.$props.development_id;
        if(dev_id){
            this.model.development_id=dev_id;
        }



        if(!this.isNewRecord){
            
                api.get(this.me(this.$props.id+'?expand=designEquipment,equiment'))
                .then(response=>{
                    
                        const data=response.data;

                        for (const key in data) {
                            if (this.model.hasOwnProperty(key)) {
                                this.model[key] = data[key];
                            }
                        }                        

                        this.model.price=this.$numberFormat(this.model.price);
                        
                        this.pic=this.$apiUrl+'/../..'+data.image.replace('.','-sm.');

                        this.$emit('onData',data);


                }).catch(error=>{
                    console.log(error);
                });

        }



    },


    watch:{

        /*
        model:{
            handler (newValue) {
                console.log(newValue.price);

            },
            deep: true

        }*/

    }


}

</script>